import React from 'react';
import './works.css';
import Card1 from '../../assets/card1.png';
import Card2 from '../../assets/card2.png';
import Card3 from '../../assets/card3.png';
import Card4 from '../../assets/card4.png';
import Card5 from '../../assets/card5.png';
import Card6 from '../../assets/card6.png';

const Works = () => {
  return (
    <section id='works'>
      <h2 className='worksTitle'>My Portfolio</h2>
      <span className='worksDesc'>
        My professional experience mainly focuses on IOS app development.
        However, this is not an obstacle to trying out new areas.
        <br></br>I have also gained some experience in web development such as HTML, CSS and JavaScript as well as in UI/UX with tools such as Figma.
        Creativity and openness to new things are among my strengths and form the foundation of my development as well as the initiative to broaden my horizons.
      </span>
      <div className='worksImgs'>
        <div className='card1Img'>
          <img src={Card1} alt='' className='worksImg' width={400} height={400}></img>
        </div>
        <div className='card2Img'>
          <img src={Card2} alt='' className='worksImg' width={400} height={400}></img>
        </div>
        <div className='card3Img'>
          <img src={Card3} alt='' className='worksImg' width={400} height={400}></img>
        </div>
        <div className='card4Img'>
          <img src={Card4} alt='' className='worksImg' width={400} height={400}></img>
        </div>
        <div className='card5Img'>
          <img src={Card5} alt='' className='worksImg' width={400} height={400}></img>
        </div>
        <div className='card6Img'>
          <img src={Card6} alt='' className='worksImg' width={400} height={400}></img>
        </div>
        {/* <div className='card6Img'>
           <img src={Card6} alt='' className='worksImg'  width={400} height={400}></img>
           </div> */}
      </div>

      <form action="https://github.com/zulfiye43/My-Github-Portfolio" method="get" target="_blank">
        <button className='workBtn'> See More</button>
      </form>
    </section>
  );
}

export default Works