import React from 'react'
import './intro.css';
import image2 from '../../assets/image2.jpeg';


const Intro = () => {
  return (
    <section id="intro">
        <div className="introContent">
<span className="hello">Hello,</span>
<span className="introText">I'm <span className="introName">Zülfiye Cakmak</span> <br></br> App & Frontend- Developer</span>
<p className="introPara">I'm passionate about creating iOS-Apps and Websites in React and Vue.js</p>
        </div>
        <img src={image2} alt="Profile" className="image2"></img>
    </section>
  
  )
}

export default Intro