import React from 'react'
import './skills.css';
import Html from '../../assets/Html.png';
import Css from '../../assets/Css.png';
import Javascript from '../../assets/Javascript.png';
import Swift from '../../assets/Swift.png';
import react from '../../assets/react.png';
import Vue from '../../assets/Vue.png';
import Figma from '../../assets/Figma.png';
import Java from '../../assets/Java.png';
import Python from '../../assets/Python.png';

const Skills = () => {
  return (
    <section id='skills'>
      <span className='skillTitle'>What I do</span>
      <div className="skillBars">
        <div className="skillBar">

        <img src={Swift} alt="Swift" className="skillBarImg"></img>
        <div className="skillBarText">
          {/* <h2>Swift</h2> */}
        </div>
        </div>

        <div className="skillBar">
        <img src={Html} alt="Html" className="skillBarImg"></img>
        <div className="skillBarText">
          {/* <h2>HTML5</h2> */}
        </div>
        </div>

        <div className="skillBar">
        <img src={Css} alt="Css" className="skillBarImg"></img>
        <div className="skillBarText">
          {/* <h2>CSS</h2> */}
        </div>
        </div>

        <div className="skillBar">
        <img src={Javascript} alt="Javascript" className="skillBarImg"></img>
        <div className="skillBarText">
          {/* <h2>Javascript</h2> */}
        </div>
        </div>

        <div className="skillBar">
        <img src={react} alt="React" className="skillBarImg"></img>
        <div className="skillBarText">
          {/* <h2>React</h2> */}
        </div>
        </div>

        <div className="skillBar">
        <img src={Vue} alt="Vue" className="skillBarImg"></img>
        <div className="skillBarText">
          {/* <h2>Vue</h2> */}
        </div>
        </div>

        <div className="skillBar">
        <img src={Figma} alt="Figma" className="skillBarImg"></img>
        <div className="skillBarText">
          {/* <h2>Figma</h2> */}
        </div>
        </div>
        
        <div className="skillBar">
        <img src={Java} alt="Java" className="skillBarImg"></img>
        <div className="skillBarText">
          {/* <h2>Java</h2> */}
        </div>
        </div>

        <div className="skillBar">
        <img src={Python} alt="Figma" className="skillBarImg"></img>
        <div className="skillBarText">
          {/* <h2>Python</h2> */}
        </div>
        </div>

      </div>
    </section>
  )
}

export default Skills