import React, {useRef} from 'react'
import './contact.css';
import Github from '../../assets/github.png';
import Linkedin from '../../assets/linkedin.png';
import emailjs from '@emailjs/browser';

const Contact = () => {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_yniqdze', 'template_j9rak3o', form.current, 'sn2mQmwVTX-aDI_j7')
      .then((result) => {
          console.log(result.text);
          e.target.reset();
          alert('Email Sent !');
      }, (error) => {
          console.log(error.text);
      });
    };
  return (
    <section id="contactPage">
     <div id="contact">
        <h1 className='contactPageTitle'>Contact Me</h1>
        <span className='contactDesc'> Please fill out the form below to discuss any work opportunities.</span>
        <form className='contactForm' ref={form} onSubmit={sendEmail}>
            <input type="text" className='name' placeholder='Your name' name='from_name'></input>
            <input type="email" className='email' placeholder='Your Email' name='from_email'></input>
            <textarea className='msg' name='message' rows="5" placeholder='Your Message'></textarea>
            <button type='submit' value='Send' className='submitBtn'>Submit</button>
            <div className='links'>
              <a href='https://github.com/zulfiye43'>
              <img src={Github} alt='Github' className='link'></img>
              </a>
              <a href='https://www.linkedin.com/in/zülfiye-cakmak-56b5a3267/'>
              <img src={Linkedin} alt='Linkedin' className='link'></img>
              </a>
            </div>
        </form>
     </div>
    </section>
  )
}

export default Contact